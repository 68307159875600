import React from 'react'
import loadable from "@loadable/component"
import * as styles from '../styles/index.module.css'
import { GatsbySeo, LogoJsonLd,  LocalBusinessJsonLd, JsonLd} from 'gatsby-plugin-next-seo';
const Layout = loadable(()=>import("../hoc/Layout/Layout"));
const Sidebar = loadable(()=> import('../components/sidebar/Sidebar'));
const Card = loadable(()=> import('../components/cards/Card/Card'));
const TechList = loadable(()=> import('../components/list/TechList/TechList'));
const ProjectList = loadable(()=> import('../components/list/ProjectList/ProjectList'));
const AboutMeTile = loadable(()=> import('../components/tile/AboutMeTile/AboutMeTile'));
const FooterCard = loadable(()=>import('../components/cards/FooterCard/FooterCard'));


export default function About() {    
    return (
        <Layout>
            <GatsbySeo
                language='en'
                title='Home - Raysummee'
                canonical='https://www.raysummee.com'
                description="Hey! Angshuman is here. Welcome to my website Raysummee. I am a flutter developer, full staker and UI/UX designer. I have developed Cizor, Crena, Raylex, Raylex studio, animore and many other apps"
                openGraph={{
                    url: 'https://www.raysummee.com',
                    title: 'Home - Raysummee',
                    type: 'profile',
                    profile: {
                        firstName: 'Angshuman',
                        lastName: 'Barpujari',
                        username: 'raysummee',
                        gender: 'male',
                    },
                    description: "Hey! Angshuman is here. Welcome to my website Raysummee. I am a flutter developer, full staker and UI/UX designer. I have developed Cizor, Crena, Raylex, Raylex studio, animore and many other apps",
                    images: [
                        {
                            url: 'https://www.raysummee.com/images/logo_wb.jpg',
                            width: 800,
                            height: 600,
                            alt: 'Logo',
                        }
                    ],
                    site_name: 'Raysummee',
                }}
            />
            <LogoJsonLd
                logo= 'https://www.raysummee.com/images/logo_wb.jpg'
                url= 'https://www.raysummee.com'
                overrides={{
                    name: 'Raysummee',
                    address: {
                        "@type": "PostalAddress",
                        "addressCountry": "IN",
                        "addressLocality": "Guwahati",
                        "addressRegion": "Assam",
                        "postalCode": "781020"
                    },
                    founder: 'Angshuman Barpujari',
                    email: 'admin@raysummee.com',
                    description: 'Empowering Entrepreneurs in App and Website Development. Raysummee is your dedicated partner for transforming your startup vision into exceptional digital solutions. With our expertise and technical know-how, your ideas will come to life, giving you a competitive edge in the digital world. Let\'s collaborate to make your startup dream a reality.'
                }}
            />
            <JsonLd
                json={{
                    "@context": "https://schema.org",
                    "@type": "Person",
                    name: "Angshuman Barpujari",
                    alumniOf: {
                        "@type": "CollegeOrUniversity",
                        name: ["The Assam Kaziranga University", "Assam Jatiya Bidyalaya"]
                    },
                    knowsAbout: ["App Development", "Machine Learning", "Web Development", "Computer Science"],
                    email: "raysummee@gmail.com",
                    gender: "male",
                    nationality: "Indian",
                    jobTitle: "SDE-I",
                }}
            />
            
            <main className={styles.about}>
                <section> 
                    <Card className={styles.general_card}>
                        <h2>About me?</h2>
                        <hr/>
                        <AboutMeTile/>
                    </Card>
                    <Card className={styles.general_card}>
                        <h2>Technologies</h2>
                        <hr/>
                        <TechList/>
                    </Card>
                    <Card className={styles.general_card}>
                        <h2>Works</h2>
                        <hr/>
                        <ProjectList/>
                    </Card>
                    <FooterCard className={styles.general_card}/>
                    <div className={styles.gutter}></div>
                </section>
                <section className={styles.sidebar}>
                    <Sidebar styles={styles}/>
                </section>
            </main>
        </Layout>
    )
}

